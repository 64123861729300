<template>
    <div>
        <div
            ref="zoomClientMainWrapper"
            style="pointer-events: none; z-index: 999999999999; background-color: transparent"
        ></div>
        <!-- <button @click="bootAdmin">admin</button>
        <button @click="bootStudent">student</button> -->
    </div>
</template>

<script>
// https://socket.io/docs/v4/client-api/
import { io } from 'socket.io-client'
import axios from "axios";

export default {
    name: 'LaiZoomClient',
    props: {
        token: {
            type: String,
            default: ''
        },
        userId: {
            type: Number,
            default: 0
        },
        courseId: {
            type: Number,
            default: 0
        },
        liveClassId: {
            type: Number,
            default: 0
        },
        leaveUrl: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            socket: {},
            userTypes: ['STUDENT'],
            tc: '',
            ipAddress: '',

            joinAudioInterval: null,

            isFullscreen: false,
            sdkKey: '',
            // leaveUrl: 'http://localhost:8080/leave/',
            // leaveUrl: 'https://live.malikampus.com/leave/',
            meetingNumber: '',
            passWord: '',
            // role: 1, // admin ya da editor ise sunucuda 1 yoksa 0 olarak ayarlanıyor
            userEmail: '',
            userName: '',
            hostConfig: {
                // videoDrag: false, //optional,
                // isSupportPolling: true, //optional
                // disablePreview: true, // optional
            },
            studentConfig: {
                showMeetingHeader: false, //option
                disableInvite: true, //optional
                disableCallOut: true, //optional
                disableRecord: true, //optional
                disableJoinAudio: false, //optional
                audioPanelAlwaysOpen: false, //optional
                showPureSharingContent: true, //optional
                isSupportAV: true, //optional,
                disablePreview: true, // optional
                videoDrag: false, //optional,
                screenShare: false, //optional,
                videoHeader: false, //optional,
                disableReport: true, // optional
                isSupportNonverbal: false, // optional,
                isSupportPolling: true, //optional


                // isLockBottom: true, // optional,
                // debug: false, //optional
                // leaveUrl: 'http://www.zoom.us', //required
                // webEndpoint: 'PSO web domain', // PSO option
                // isSupportQA: true, //optional,
                // isSupportBreakout: true, //optional
                // isSupportCC: false, //optional,
                // rwcBackup: '', //optional,
                // sharingMode: 'both', //optional,
                // isShowJoiningErrorDialog: true, // optional,
                // disableCORP: true, // optional
                // inviteUrlFormat: '', // optional
                // loginWindow: {  // optional,
                //     width: 400,
                //     height: 380
                // },
                // meetingInfo: [ // optional
                //     'topic',
                //     'host',
                //     'mn',
                //     'pwd',
                //     'telPwd',
                //     'invite',
                //     'participant',
                //     'dc',
                //     'enctype',
                //     'report'
                // ],
                disableVoIP: false, // optional
            },
            // willBeDeleted: [
            //     ".meeting-info-container--left-side", // sol üstteki bilgiler
            //     // ".meeting-info-container--right-side", // sağdaki tam ekran menüleri
            //     {
            //         selector: ".meeting-info-container--right-side > .meeting-info-container__full-screen > .full-screen-icon > .dropdown > ul > *:not(li:last-of-type)",
            //         bulk: true
            //     },
            //     // ".join-dialog", // açılışta join audio computer alanı
            //     // ".join-audio-container", // sol alt ses butonu
            //     ".send-video-container", // sol alt video butonu
            //     {
            //         selector: '.footer__inner .footer-button__participants-icon',
            //         parent: 2,
            //         inner: true
            //     },
            //     // {
            //     //     selector: '.footer__inner .footer-button__no-record-icon',
            //     //     parent: 2,
            //     //     inner: true
            //     // },
            //     ".footer__inner .more-button", // daha fazla butonu
            //     "#notificationManager", // üstteki notification alanı
            //     ".speaker-bar-container__horizontal-view-wrap" // üstte diğer kullanıcıların görüldüğü alan
            //     // ".security-option-menu", // güvenlik butonu
            //     // ".sharing-setting-dropdown-menu-container"
            // ]
        }
    },
    computed: {
        isHost () {
            return this.userTypes.includes('ADMIN') || this.userTypes.includes('TEACHER')
        },
        isTeacher () {
            return this.userTypes.includes('TEACHER')
        },
        showZoom () {
            return this.token && this.courseId > 0 && this.liveClassId > 0 && this.userId && this.leaveUrl
        }
    },
    watch: {
        async showZoom (newValue) {
            if (newValue)
                if (await this.setUserData())
                    this.getSignature()
        }
    },
    methods: {
        boot () {
            // socket serverina baglanalim
            this.socket = io('wss://socket.malikampus.com', {
                // this.socket = io('ws://localhost:6220', {
                // auht, query ...
            })

            window.ZoomMtg.setZoomJSLib('https://source.zoom.us/2.18.2/lib', '/av')
            window.ZoomMtg.preLoadWasm()
            window.ZoomMtg.prepareJssdk()

            // kendi türkçe dil objemiz "/lang/tr-TR.json" bu dosya üzerinde düzenleme yapılabilir.
            window.ZoomMtg.i18n.load("/lang/tr-TR.json", "tr-TR");
            window.ZoomMtg.i18n.reload('tr-TR');
        },
        // bootAdmin () {       
        //     this.token = 'ad2a63b7b64de8c42b4d7539638ad70a5cb45435'
        //     this.courseId = 1
        //     this.liveClassId = 9
        //     this.userTypes = ['ADMIN']

        //     this.getSignature()
        // },
        // bootStudent () {
        //     this.token = '83e0bd7f28d94acc3d61a56236a7bc58dc569476'
        //     this.courseId = 1
        //     this.liveClassId = 9
        //     this.userTypes = ['STUDENT']

        //     this.getSignature()
        // },
        setWatermarks () {
            const tc = document.createElement('p')
            tc.className = 'watermark'
            tc.innerHTML = this.tc
            const ip = document.createElement('p')
            ip.className = 'watermark'
            ip.innerHTML = this.ipAddress
            ip.style.top = '50px'

            const wrapper = this.$refs.zoomClientMainWrapper
            wrapper.style.position = 'absolute'
            wrapper.style.width = `${window.clientWidth}px`
            wrapper.style.height = `${window.clientHeight}px`
            wrapper.style.top = `${window.offsetTop}px`
            wrapper.style.left = `${window.offsetLeft}px`

            if (this.watermarkInterval === undefined) {
                wrapper.appendChild(tc)
                wrapper.appendChild(ip)

                const watermarks = document.querySelectorAll('.watermark')
                this.watermarkInterval = setInterval(() => {
                    watermarks.forEach(mark => {
                        mark.style.left = `${(window.innerWidth - mark.offsetWidth) * Math.random()}px`
                        mark.style.top = `${(window.innerHeight - mark.offsetHeight) * Math.random()}px`
                    })
                }, 10000)
            }
        },
        async setUserData () {
            const ipAddress = await axios.get('https://api.malikampus.com/api/v1/get-ip-address')
            // const ipAddress = await axios.get('https://api.malikampus.com/api/v1/get-ip-address')
            if (!ipAddress)
                return false

            this.ipAddress = ipAddress.data.ip

            const user = await axios.get(`https://api.malikampus.com/api/v1/users/${this.userId}`, {
                // const user = await axios.get(`https://api.malikampus.com/api/v2/users/${this.userId}`, {
                headers: {
                    'Authorization': `Token ${this.token}`
                }
            })
            if (!user)
                return false

            this.tc = user.data.tc_kimlik_number
            this.userTypes = user.data.auth_role

            return true
        },
        getSignature () {
            let endpoint
            if (this.userTypes.includes('ADMIN') || this.userTypes.includes('TEACHER')) {
                endpoint = `https://api.malikampus.com/api/v2/courses/${this.courseId}/live-classes/${this.liveClassId}/teacher-signature/`
            } else {
                endpoint = `https://api.malikampus.com/api/v2/courses/${this.courseId}/live-classes/${this.liveClassId}/signature/`
            }
            axios.get(
                endpoint,
                // `https://api.malikampus.com/api/v2/courses/${this.courseId}/live-classes/${this.liveClassId}/signature/`,
                // `https://api.malikampus.com/api/v2/courses/${this.courseId}/live-classes/${this.liveClassId}/signature/`,
                {
                    headers: {
                        Authorization: `Token ${this.token}`
                    }
                })
                .then(res => {
                    this.sdkKey = res.data.sdkKey
                    this.meetingNumber = res.data.meetingNumber
                    this.passWord = res.data.passWord
                    this.userEmail = res.data.userEmail
                    this.userName = res.data.userName

                    this.startMeeting(res.data.signature)

                    // leave url de kullanılmak üzere gerekli bilgileri local storage a kaydediyoruz
                    // localStorage.setItem('token', this.token);
                    // localStorage.setItem('courseId', this.courseId);
                    // localStorage.setItem('liveClassId', this.liveClassId);
                })
                .catch(error => {
                    console.log(error)
                })
        },
        activateAudio () {
            this.joinAudioInterval = setInterval(() => {
                const joinAudioBtn = document.querySelector('.join-audio-container__btn[aria-label="join audio"]')
                if (joinAudioBtn) {
                    // 'unmute my microphone' 'mute my microphone' 'join audio'
                    joinAudioBtn.click()
                    clearInterval(this.joinAudioInterval)
                    this.joinAudioInterval = null
                }
            }, 1000)
        },
        startMeeting (signature) {
            document.getElementById('zmmtg-root').style.display = 'block'

            const configAccordingToUserType = this.userTypes.includes('ADMIN') ||
                this.userTypes.includes('TEACHER')
                ? this.hostConfig
                : this.studentConfig

            window.ZoomMtg.init({
                ...configAccordingToUserType,

                leaveUrl: this.leaveUrl,
                isSupportAV: true,
                success: () => {
                    window.ZoomMtg.join({
                        meetingNumber: this.meetingNumber,
                        userName: this.userName,
                        signature: signature,
                        sdkKey: this.sdkKey,
                        userEmail: this.userEmail,
                        passWord: this.passWord,
                        success: () => {
                            if (this.isHost) {
                                if (this.isTeacher) {
                                    // ögrencilerde egitime katilabilmek icin buton cikaralim
                                    this.socket.emit('initiate-course', this.courseId)

                                    document.querySelector('html').classList.add('meeting-teacher')
                                }
                                document.querySelector('html').classList.add('meeting-host')
                                this.setChatConfig()
                            } else {
                                this.setWatermarks()
                                // bu method otomatik olarak sistem sesini kullanmak icindi
                                // ancak bazi bilgisayarlarda sorun yasatiyor
                                // this.clickJoinDialogButton()
                                setTimeout(() => {
                                    this.activateAudio()
                                }, 5000)
                                // this.removeElementForStudent()
                            }

                        },
                        error: (error) => {
                            console.log(error)
                        }
                    })
                },
                error: (error) => {
                    console.log(error)
                }
            })

            window.ZoomMtg.inMeetingServiceListener('onMeetingStatus', (data) => {
                // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
                // console.log('inMeetingServiceListener onMeetingStatus', data)
                // 2 => meeting baslatildi
                if (data?.meetingStatus === 2) {
                    // http requestte başka bir sorun olabileceği gerekçesiyle sorgudan önce event fırlatmak
                    window.top.postMessage('meetingConnected', '*')

                    window.top.postMessage('meetingConnectedRequest', '*')
                    // axios({
                    //     method: 'patch',
                    //     url: `https://api.malikampus.com/api/v2/courses/${this.courseId}/live-classes/${this.liveClassId}`,
                    //     data: {
                    //         is_live: true
                    //     },
                    //     headers: {
                    //         Authorization: `Token ${this.token}`
                    //     }
                    // })
                    // .then( () => {
                    //     
                    // })
                    // .catch( error => {
                    //     console.error(error)
                    // })
                }
            })
        },
        setChatConfig () {
            var isChatOpened = false
            const setChatConfigInterval = setInterval(() => {

                const chatButton = document.querySelector("[aria-label*='chat']")

                const onChatOpenedForChatButton = () => {
                    const onChatOpenedForChatButtonInterval = setInterval(() => {
                        if (document.querySelector(".chat-container.window-content-bottom")) {
                            isChatOpened = true
                            chatButton.removeEventListener("click", onChatOpenedForChatButton)
                            clearInterval(onChatOpenedForChatButtonInterval)
                        }
                    }, 1000)
                }

                if (!isChatOpened && chatButton) {
                    chatButton.addEventListener("click", onChatOpenedForChatButton)
                    chatButton.click()
                }

                if (document.querySelector(".chat-container__chat-control ul li:nth-child(3) a")) {
                    document.querySelector(".chat-container__chat-control ul li:nth-child(3) a").click()
                    clearInterval(setChatConfigInterval)
                }
            }, 1000)

        },
        clickJoinDialogButton () {
            const selectJoinDialogButtonInterval = setInterval(() => {
                const joinDialogButton = document.querySelector(".zm-btn.join-audio-by-voip__join-btn")

                if (joinDialogButton) {
                    clearInterval(selectJoinDialogButtonInterval)

                    const joinDialogButtonClickHandle = () => {
                        clearInterval(clickJoinDialogButtonInterval)
                        joinDialogButton.removeEventListener("click", joinDialogButtonClickHandle)
                    }
                    joinDialogButton.addEventListener("click", joinDialogButtonClickHandle)

                    const clickJoinDialogButtonInterval = setInterval(function () {
                        joinDialogButton.click()
                    }, 500)
                }
            }, 500)
        },
        // removeElementForStudent() {
        //     const removeElementForStudentInterval = setInterval( () => {
        //         this.willBeDeleted.forEach(function(currentValue, index, array) {
        //             if ( typeof currentValue === 'object' && currentValue !== null ) {
        //                 if ( currentValue.bulk ) {
        //                     if ( document.querySelectorAll(currentValue.selector) ) {
        //                         const elementsList = document.querySelectorAll(currentValue.selector)
        //                         elementsList.forEach((currentElement) => {
        //                             currentElement.remove()
        //                         })
        //                         array.splice(index, 1)
        //                     }
        //                 } else {
        //                     if ( document.querySelector(currentValue.selector) ) {
        //                         let element = document.querySelector(currentValue.selector)
        //                         for ( let i = 1; i <= currentValue.parent; i++ ) {
        //                             element = element.parentNode
        //                         }

        //                         if ( currentValue.inner ) {
        //                             element.innerHTML = ""
        //                         } else {
        //                             element.remove()
        //                         }
        //                         array.splice(index, 1)
        //                     }
        //                 }
        //             } else {
        //                 if ( document.querySelector(currentValue) ) {
        //                     document.querySelector(currentValue).remove()
        //                     array.splice(index, 1)
        //                 }
        //             }
        //         })

        //         if ( ! this.willBeDeleted.length )
        //             clearInterval(removeElementForStudentInterval)

        //         console.log("removeElementForStudent")
        //         console.log(this.willBeDeleted)
        //         console.log("removeElementForStudent")

        //     }, 1000)
        // }
    },
    mounted () {
        this.boot()
    },
    beforeDestroy() {
        clearInterval(this.joinAudioInterval)
        this.joinAudioInterval = null
    },
}
</script>

<style>
#zmmtg-root {
    display: none;
}

p.watermark {
    position: absolute;
    top: 20px;
    left: 20px;
    text-shadow: 0px 0px 8px black;
    color: white;
    opacity: 0.4;
    font-size: 18px;
    font-weight: bold;
}

/* footer butonların soldaki ilk bölümü. kamera ve mikrofon aç-kapat */
html:not(.meeting-host) footer#wc-footer > .footer__inner > div:first-child > * {
    display: none !important;
}

/* ekranın sol üst köşesindeki görüşme hakkında bilgilendirme ve görüşmenin kaydı ile ilgili pause stop vb. butonlar */
.meeting-info-container.meeting-info-container--left-side {
    display: none !important;
}

/* Ekran genişliği daraldığında alttaki butonlar Daha Fazla isimli bir buton altına giriyor. */
html:not(.meeting-host) .footer__inner .more-button {
    display: none !important;
}

/* Ekranın alt kısmındaki "Güvenlik" etiketli buton */
html:not(.meeting-host) .security-option-menu {
    display: none !important;
}

/* Ekranın alt kısmındaki "Katılımcılar" etiketli buton */
[aria-label*="participant"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Anketler" etiketli buton */
html:not(.meeting-host) [aria-label*="polling"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Ekran Paylaş" etiketli buton ve yan ögeleri */
html:not(.meeting-host) .sharing-entry-button-container {
    display: none !important;
}

/* Ekranın alt kısmındaki "Pause/Stop Recording" etiketli butonlar */
html:not(.meeting-host) .footer-button-pause-stop-recording {
    display: none !important;
}

/* Ekranın alt kısmındaki "Reactions" etiketli buton */
html:not(.meeting-host) [aria-label*="Reactions"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Settings" etiketli buton */
html:not(.meeting-host) [aria-label*="Settings"] {
    display: none !important;
}

/* notification manager */
html:not(.meeting-host) #notificationManager {
    display: none !important;
}
</style>
