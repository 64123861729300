<template>
    <div class="home">
        <zoom-client
            v-if="$route.params.incomingInfo"
            :token="token"
            :course-id="courseId"
            :live-class-id="liveClassId"
            :leave-url="leaveUrl"
            :userId="userId"
        />
    </div>
</template>

<script>
import ZoomClient from '@/components/ZoomClient.vue'

export default {
    name: 'Home',
    components: {
        ZoomClient
    },
    data () {
        return {
            token: null,
            courseId: 0,
            liveClassId: 0,
            leaveUrl: '',
            userId: null
        }
    },
    mounted () {
        if (this.$route.params.incomingInfo) {
            const incomingInfo = JSON.parse(atob(this.$route.params.incomingInfo))
            let currentTime = new Date();
            // en geç 1 dakika içinde oluşturulmuş olmalı
            if (incomingInfo.time + (60 * 1000) > currentTime.getTime() || window.location.origin == 'http://localhost:8082') {
                this.token = incomingInfo.token
                this.courseId = incomingInfo.course
                this.liveClassId = incomingInfo.live
                this.leaveUrl = incomingInfo.host
                this.userId = incomingInfo.userId
            } else {
                console.log('Aso Error Code: 1005')
            }

        }
    }
}
</script>